// export const categoryList = [
//   {
//     id: 1,
//     value: 'place',
//     label: '🚩 Places',
//   },
//   {
//     id: 2,
//     value: 'dish',
//     label: '🍕 Dishes',
//   },
// ];

export const ratingList = [
  {
    id: 1,
    value: '1',
    label: '1🌟',
  },
  {
    id: 2,
    value: '2',
    label: '2🌟',
  },
  {
    id: 3,
    value: '3',
    label: '3🌟',
  },
  {
    id: 4,
    value: '4',
    label: '4🌟',
  },
  {
    id: 5,
    value: '5',
    label: '5🌟',
  },
];

export const dataList = [
  {
    id: 1,
    title: 'lounge resort',
    descriptivetype: 'enclosures',
    interpretation: 'settlement',
    cuisine: 'sudan',
    coverSrc: '/images/places/1_1img.jpg',
    coverSrc1:'/images/places/1_2img.jpg',
    coverSrc2:'/images/places/1_3img.jpg',
    specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotSentinelFAKE.jpg',
    gridcode: '7447',
    sitename: 'Uri',
    recordername:'Ed Burnett',
    stateregion: 'North Darfur',
    daterecord: '02/28/2023',
    descriptionimageone: 'The enclosures that make up part of the settlement of Uri are clearly visible in this image, as the angle of the sun has created strong shadows alongside walling. However, the vegetation on the bottom part of the image may make identifying enclosures here more difficult. Similar enclosures, which often indicate settlements, can be found across Darfur.',
    descriptionimagetwo: 'In this earlier image, the lower image quality and less convenient sun angle makes the enclosures harder to identify. However, the lower amount of vegetation at the bottom of the image may make enclosures in this specific location easier to identify.',
    descriptionimagethree: 'The low levels of contrast in this image may make these enclosures even harder to identify, although they are still reasonably clear.',
    sourceone:'Google Earth',
    sourcetwo:'Google Earth',
    sourcethree:'Google Earth',
    dateofimageione: '11/11/2022',
    dateofimagetwo: '30/04/2005',
    dateofimagethree: '28/12/2012',
    adminlevel: 'North Darfur',
    seasonalconditionsprimary: 'Wet',
    seasonalconditionsalternative1: 'Dry',
    seasonalconditionsalternative2: 'Intermediate',
    seasonalconditionsalternative3: 'Dry',
    otherconditionsprimary: 'Low vegetation',
    otherconditionsalternative1: 'Low sun angle',
    otherconditionsalternative2: '',
    otherconditionsalternative3:'',
  },
  {
    id: 2,
    title: 'door urban',
    descriptivetype: 'structure',
    interpretation: 'mosque',
    cuisine: 'sudan',
    coverSrc: '/images/places/2_1img.jpg',
    coverSrc1: '/images/places/2_2img.jpg',
    coverSrc2: '/images/places/2_3img.jpg',
    specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotLandsatFAKE.jpg',
    gridcode: '8167',
    sitename: 'Gami Kurro',
    stateregion: 'North Darfur',
    recordername:'Ed Burnett',
    daterecord: '02/28/2023',
    descriptionimageone: 'In this image, high square walls can be seen around a series of regular columns (within a larger rectangular compound), oriented in the direction of Mecca, making the feature identifiable as a mosque. The high sun angle makes the walls and columns very clear, despite the low quality of the image.',
    descriptionimagetwo: 'In this image, vegetation slightly obscures the mosque, although the clear shadows from its walls and discolouration of the ground around it make it still recognisable.', 
    descriptionimagethree: 'In this image, the lower angle of the sun may make the internal columns harder to identify, as their shadows are lengthened.', sourceone:'Google Earth',
    sourcetwo:'Google Earth',
    sourcethree:'Google Earth',
    dateofprimary: '30/04/2005',
    dateofimageione: '11/20/2022',
    dateofimagetwo: '21/06/2019',
    dateofimagethree: '23/11/2020',
    adminlevel: 'North Darfur',
    seasonalconditionsprimary: 'Dry',
    seasonalconditionsalternative1: 'Wet',
    seasonalconditionsalternative2: 'Intermediate',
    seasonalconditionsalternative3: 'Dry',
    otherconditionsprimary: 'High sun angle',
    otherconditionsalternative1: 'Low sun angle',
    otherconditionsalternative2: '',
    otherconditionsalternative3:'',
  },
  {
    id: 3,
    title: 'chow',
    descriptivetype: 'tumuli',
    interpretation: 'cemetery',
    cuisine: 'sudan',
    coverSrc: '/images/places/3_1img.jpg',
    coverSrc1: '/images/places/3_2img.jpg',
    coverSrc2: '/images/places/3_3img.jpg',
    specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotLandsatFAKE.jpg',
    gridcode: '12241',
    sitename: 'Uri',
    recordername:'Ed Burnett',
    stateregion: 'North Kordofan',
    daterecord: '06/30/2023',
    descriptionimageone: 'The piled rocks that make up these tumuli can be clearly identified in this image as round shapes that appear to protrude from the ground around them. Many have smaller white circles within, which may indicate that the inner part of the tumulus has collapsed.',
    descriptionimagetwo: 'In this earlier image, the tumuli are in some ways harder to identify, due to the lower quality of the image. However, the higher amount of contrast may make them easier for some surveyors to distinguish. In addition, by comparing this image to the previous image, some vegetation in the area which might be mistaken for tumuli can be distinguished (as it changes in size/shape between the images).',
    descriptionimagethree: 'The tumuli are more muted in this image, making it harder to distinguish them from the background terrain, but easier to distinguish them from the small amount of vegetation in this area.',
    sourceone:'Google Earth',
    sourcetwo:'Google Earth',
    sourcethree:'Google Earth',
    dateofimageione: '28/12/2012',
    dateofimagetwo: '15/01/2005',
    dateofimagethree: '27/06/2019',
    adminlevel: 'North Darfur',
    seasonalconditionsprimary: 'Dry',
    seasonalconditionsalternative1: 'Wet',
    seasonalconditionsalternative2: 'Intermediate',
    seasonalconditionsalternative3: 'Dry',
    otherconditionsprimary: 'Low vegetation',
    otherconditionsalternative1: 'Low sun angle',
    otherconditionsalternative2: '',
    otherconditionsalternative3:'',
  },
  {
    id: 4,
    title: 'chow',
    descriptivetype: 'cairns',
    interpretation: 'burial',
    cuisine: 'tanzania',
    coverSrc: '/images/places/4_1img.jpg',
    coverSrc1: '/images/places/4_2img.jpg',
    coverSrc2: '/images/places/4_3img.jpg',
    specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotLandsatFAKE.jpg',
    gridcode: '34951',
    sitename: 'Mavumbi',
    recordername:'Akinbowale Akintayo',
    stateregion: 'Tanga',
    daterecord: '27/06/2023',
    descriptionimageone: 'Although the stone cairn has been destroyed as evident from the imagery, the signature is still clearly visible on the imagery. The presence of possible rubbles also attests to this. There are couple of other cairns located few metres away. There is a light forest with trees scattered around the cairn',
    descriptionimagetwo: 'In the alternative image, the vegetation has become denser which in turn has made the cairn difficult to see fully as it is the case in the earlier image',
    descriptionimagethree: 'The cairn is clearly not visible from this image and makes it quite difficult to identify',
    sourceone:'Google Earth',
    sourcetwo:'Google Earth',
    sourcethree:'Google Earth',
    dateofimageione: '12/03/2019',
    dateofimagetwo: '10/01/2015',
    dateofimagethree: '28/12/2012',
    adminlevel: 'Tanga',
    seasonalconditionsprimary: 'Dry',
    seasonalconditionsalternative1: 'Wet',
    seasonalconditionsalternative2: 'Intermediate',
    seasonalconditionsalternative3: 'Dry',
    otherconditionsprimary: 'Low vegetation',
    otherconditionsalternative1: 'Low sun angle',
    otherconditionsalternative2: '',
    otherconditionsalternative3:'',
  },
  {
    id: 5,
    title: 'chow',
    descriptivetype: 'structure',
    interpretation: 'settlement',
    cuisine: 'tanzania',
    coverSrc: '/images/places/5_1img.jpg',
    coverSrc1: '/images/places/5_2img.jpg',
    coverSrc2: '/images/places/5_3img.jpg',
    specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotSentinelFAKE.jpg',
    gridcode: '29549',
    sitename: 'Kua ruins',
    stateregion: 'Pwani',
    daterecord: '06/18/2023',
    descriptionimageone: 'The ruins is clearly visible on the image. The outlines of the structures are still visible on the imagery. The adjoining area is heavily vegetated.',
    descriptionimagetwo: 'The image is clear enough to see the outline of the ruins although the area is still heavily forested.',
    descriptionimagethree: 'The ruins are not visibly clear as they were in the earlier image but the ruins can still be spotted. The area has also become more vegetated.',
    sourceone:'Google Earth',
    sourcetwo:'Google Earth',
    recordername:'Akinbowale Akintayo',
    sourcethree:'Google Earth',
    dateofimageione: '11/07/2013',
    dateofimagetwo: '18/06/2014',
    dateofimagethree: '22/06/2017',
    adminlevel: 'Pwani',
    seasonalconditionsprimary: 'Wet',
    seasonalconditionsalternative1: 'Wet',
    seasonalconditionsalternative2: 'Wet',
    seasonalconditionsalternative3: 'Dry',
    otherconditionsprimary: '',
    otherconditionsalternative1: '',
    otherconditionsalternative2: '',
    otherconditionsalternative3:'',
  },
  {
    id: 6,
    title: 'chow',
    descriptivetype: 'surface deposit',
    interpretation: 'Notdiscernible',
    cuisine: 'botswana',
    coverSrc: '/images/places/6_1img.jpg',
    coverSrc1: '/images/places/6_1img.jpg',
    coverSrc2: '/images/places/6_1img.jpg',
    specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotPlanetFAKE.jpg',
    gridcode: '6802',
    sitename: 'Kgalagadi ',
    stateregion: 'Limpu',
    daterecord: '17/08/2023',
    descriptionimageone: 'This landscape of the Kalahari Desert of Botswana exhibits a complex of salt pans which may partly be filled to shallow water depths during the rainy season. There is good evidence that they have attracted human and animal populations throughout the millenials as eveidenced by stone age artefacts found scattered around them.The water pans are usually dry and they dont retain water over long period of time as seen in this image.',
    descriptionimagetwo: 'There are some dark marks on some pans in this historic image, which could be associated with wet mud residue left behind when the water evaporate from the water pans. Despite that, no significant change is noticed when comparing to recent images.  ',
    descriptionimagethree: 'Thewater pans continues to shrink in size and numbers as less rainfall is recorded and some are covered by the sand which have potential of burying the artefacts around them. ', 
    sourceone:'Google Earth',
    sourcetwo:'Google Earth',
    sourcethree:'Google Earth',
    dateofimageione: '28/06/2023',
    dateofimagetwo: '31/12/1984',
    recordername:'Thabo Kgosietsile',
    dateofimagethree: '31/12/1991',
    seasonalconditionsprimary: 'Dry',
    seasonalconditionsalternative1: 'Dry',
    seasonalconditionsalternative2: 'Dry',
    seasonalconditionsalternative3: 'Dry',
    otherconditionsprimary: 'No vegetation',
    otherconditionsalternative1: 'No vegetation',
    otherconditionsalternative2: 'No vegetation',
    otherconditionsalternative3:'',
  },
  
  // {
  //   id: 10,
  //   title: 'chow',
  //   descriptivetype: 'cairns',
  //   interpretation: 'tumuli',
  //   cuisine: 'botswana',
  //   coverSrc: '/images/places/SA_Test_KordTumuli_3.jpg',
  //   specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotPlanetFAKE.jpg',
  //   gridcode: '7447',
  //   sitename: 'Gami Kurro',
  //   stateregion: 'North Kordofan',
  //   daterecord: '05/15/2023',
  //   descriptionimageone: 'The enclosures that make up part of the settlement of Uri are clearly visible in this image, as the angle of the sun has created strong shadows alongside walling. However, the vegetation on the bottom part of the image may make identifying enclosures here more difficult. Similar enclosures, which often indicate settlements, can be found across Darfur.',
  //   descriptionimagetwo: 'In this earlier image, the lower image quality and less convenient sun angle makes the enclosures harder to identify. However, the lower amount of vegetation at the bottom of the image may make enclosures in this specific location easier to identify.',
  //   descriptionimagethree: 'The low levels of contrast in this image may make these enclosures even harder to identify, although they are still reasonably clear.',
  //   sourceone:'Google Earth',
  //   sourcetwo:'Google Earth',
  //   sourcethree:'Google Earth',
  //   dateofimageione: '15/11/2023',
  //   dateofimagetwo: '30/04/2005',
  //   dateofimagethree: '28/12/2012',
  //   adminlevel: 'North Kordofan',
  //   seasonalconditionsprimary: 'Dry',
  //   recordername:'Ed Burnett',
  //   seasonalconditionsalternative1: 'Wet',
  //   seasonalconditionsalternative2: 'Intermediate',
  //   seasonalconditionsalternative3: 'Dry',
  //   otherconditionsprimary: 'Low vegetation',
  //   otherconditionsalternative1: 'Low sun angle',
  //   otherconditionsalternative2: '',
  //   otherconditionsalternative3:'',
  // },
  // {
  //   id: 11,
  //   title: 'chow',
  //   descriptivetype: 'cairns',
  //   interpretation: 'tumuli',
  //   cuisine: 'botswana',
  //   coverSrc: '/images/places/SA_Test_KordTumuli_3.jpg',
  //   specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotLandsatFAKE.jpg',
  //   gridcode: '7447',
  //   sitename: 'Uri',
  //   stateregion: 'North Darfur',
  //   daterecord: '05/01/2023',
  //   descriptionimageone: 'The enclosures that make up part of the settlement of Uri are clearly visible in this image, as the angle of the sun has created strong shadows alongside walling. However, the vegetation on the bottom part of the image may make identifying enclosures here more difficult. Similar enclosures, which often indicate settlements, can be found across Darfur.',
  //   descriptionimagetwo: 'In this earlier image, the lower image quality and less convenient sun angle makes the enclosures harder to identify. However, the lower amount of vegetation at the bottom of the image may make enclosures in this specific location easier to identify.',
  //   descriptionimagethree: 'The low levels of contrast in this image may make these enclosures even harder to identify, although they are still reasonably clear.',
  //   sourceone:'Google Earth',
  //   sourcetwo:'Google Earth',
  //   sourcethree:'Google Earth',
  //   dateofimageione: '13/06/2023',
  //   dateofimagetwo: '30/04/2005',
  //   dateofimagethree: '28/12/2012',
  //   adminlevel: 'North Darfun',
  //   seasonalconditionsprimary: 'Dry',
  //   seasonalconditionsalternative1: 'Wet',
  //   seasonalconditionsalternative2: 'Intermediate',
  //   seasonalconditionsalternative3: 'Dry',
  //   recordername:'Ed Burnett',
  //   otherconditionsprimary: 'Low vegetation',
  //   otherconditionsalternative1: 'Low sun angle',
  //   otherconditionsalternative2: '',
  //   otherconditionsalternative3:'',
  // },
  // {
  //   id: 12,
  //   title: 'chow',
  //   descriptivetype: 'cairns',
  //   interpretation: 'tumuli',
  //   cuisine: 'botswana',
  //   coverSrc: '/images/places/SA_Test_KordTumuli_3.jpg',
  //   specSrc: '/images/places/SA_Test_KordTumuli_SpectralPlotSentinelFAKE.jpg',
  //   gridcode: '7447',
  //   sitename: 'Gami Kurro',
  //   stateregion: 'North Kordofan',
  //   recordername:'Ed Burnett',
  //   daterecord: '04/22/2023',
  //   descriptionimageone: 'The enclosures that make up part of the settlement of Uri are clearly visible in this image, as the angle of the sun has created strong shadows alongside walling. However, the vegetation on the bottom part of the image may make identifying enclosures here more difficult. Similar enclosures, which often indicate settlements, can be found across Darfur.',
  //   descriptionimagetwo: 'In this earlier image, the lower image quality and less convenient sun angle makes the enclosures harder to identify. However, the lower amount of vegetation at the bottom of the image may make enclosures in this specific location easier to identify.',
  //   descriptionimagethree: 'The low levels of contrast in this image may make these enclosures even harder to identify, although they are still reasonably clear.',
  //   sourceone:'Google Earth',
  //   sourcetwo:'Google Earth',
  //   sourcethree:'Google Earth',
  //   dateofimageione: '15/06/2023',
  //   dateofimagetwo: '30/04/2005',
  //   dateofimagethree: '28/12/2012',
  //   adminlevel: 'North Kordofan',
  //   seasonalconditionsprimary: 'Dry',
  //   seasonalconditionsalternative1: 'Wet',
  //   seasonalconditionsalternative2: 'Intermediate',
  //   seasonalconditionsalternative3: 'Dry',
  //   otherconditionsprimary: 'Low vegetation',
  //   otherconditionsalternative1: 'Low sun angle',
  //   otherconditionsalternative2: '',
  //   otherconditionsalternative3:'',
  // },
  // {
  //   id: 4,
  //   title: 'wharf seafood',
  //   serviceTime: '10-18min',
  //   deliveryFee: 2.14,
  //   category: 'place',
  //   cuisine: 'american',
  //   rating: 1,
  //   price: 1800,
  //   coverSrc: '/images/places/sea.jpg',
  // },
  // {
  //   id: 5,
  //   title: 'Tossin',
  //   serviceTime: '25-30min',
  //   deliveryFee: 6.79,
  //   category: 'place',
  //   cuisine: 'italian',
  //   rating: 5,
  //   price: 2000,
  //   coverSrc: '/images/places/italiian.jpg',
  // },
  // {
  //   id: 6,
  //   title: 'stories noodles',
  //   serviceTime: '5-15min',
  //   deliveryFee: 2.87,
  //   category: 'place',
  //   cuisine: 'chinese',
  //   rating: 5,
  //   price: 3500,
  //   coverSrc: '/images/places/fookyew2.jpg',
  // },
  // {
  //   id: 7,
  //   title: 'Nacho cheeseburger',
  //   serviceTime: '50-65min',
  //   deliveryFee: 8.5,
  //   category: 'dish',
  //   cuisine: 'american',
  //   rating: 2,
  //   price: 2200,
  //   coverSrc: '/images/dishes/nacho-burger.jpg',
  // },
  // {
  //   id: 8,
  //   title: 'Mushroom Risotto',
  //   serviceTime: '10-15min',
  //   deliveryFee: 1.8,
  //   category: 'dish',
  //   cuisine: 'italian',
  //   rating: 1,
  //   price: 1900,
  //   coverSrc: '/images/dishes/Mushroom-Risotto-Recipe-1-1200.jpg',
  // },
  // {
  //   id: 9,
  //   title: 'Shitake Fried Rice',
  //   serviceTime: '12-18min',
  //   deliveryFee: 3.33,
  //   category: 'dish',
  //   cuisine: 'chinese',
  //   rating: 4,
  //   price: 2750,
  //   coverSrc: '/images/dishes/shiitake-salmon-fried-rice-0218-103230720.jpg',
  // },
  // {
  //   id: 10,
  //   title: 'Pesto chicken stew',
  //   serviceTime: '30-38min',
  //   deliveryFee: 1.9,
  //   category: 'dish',
  //   cuisine: 'american',
  //   rating: 2,
  //   price: 4350,
  //   coverSrc: '/images/dishes/pesto-chicken.jpg',
  // },
  // {
  //   id: 11,
  //   title: 'Bruschetta',
  //   serviceTime: '16-20min',
  //   deliveryFee: 4.1,
  //   category: 'dish',
  //   cuisine: 'italian',
  //   rating: 3,
  //   price: 3300,
  //   coverSrc: '/images/dishes/as-tomato-bruschetta-articleLarge.jpg',
  // },
  // {
  //   id: 12,
  //   title: 'Dim Sums',
  //   serviceTime: '24-30min',
  //   deliveryFee: 1.5,
  //   category: 'dish',
  //   cuisine: 'chinese',
  //   rating: 2,
  //   price: 4100,
  //   coverSrc: '/images/dishes/dimsums.jpg',
  // },
];
